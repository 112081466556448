import * as requestFromServer from "./ReplaceEmoneyCrud";
import { ReplaceEmoneySlice } from "./ReplaceEmoneySlice";
import { UISlice } from "../UISlice";

const { actions: ReplaceEmoneyActions } = ReplaceEmoneySlice;
const { actions: UIActions } = UISlice;

export const fetchReplaceEmoneyRequests =
  (type, searchTerm = "", page = 1) =>
  (dispatch) => {
    dispatch(UIActions.openLoader());

    let fetchFunction;
    switch (type) {
      case "pending":
        fetchFunction = requestFromServer.pendingEMoneyRequestsApi;
        break;
      case "accepted":
        fetchFunction = requestFromServer.acceptedEMoneyRequestsApi;
        break;
      case "rejected":
      default:
        fetchFunction = requestFromServer.rejectedEMoneyRequestsApi;
        break;
    }

    return fetchFunction(searchTerm, page)
      .then((response) => {
        if (response.data.status) {
          dispatch(
            ReplaceEmoneyActions.setReplaceEmoneyData({
              data: response.data.data,
              paginator: response.data.paginator,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(ReplaceEmoneyActions.catchError(error));
      })
      .finally(() => {
        dispatch(UIActions.closeLoader());
      });
  };

export const viewReplaceEmoneyDetails = (id) => (dispatch) => {
  dispatch(UIActions.openLoader());

  return requestFromServer
    .viewEMoneyDetailsApi(id)
    .then((response) => {
      if (response.data.status) {
        dispatch(
          ReplaceEmoneyActions.setReplaceEmoneyDetails(response.data.data)
        );
      }
    })
    .catch((error) => {
      dispatch(ReplaceEmoneyActions.catchError(error));
    })
    .finally(() => {
      dispatch(UIActions.closeLoader());
    });
};

export const acceptReplaceEmoneyRequest =
  (id, acceptanceNote, onSuccess) => (dispatch) => {
    dispatch(UIActions.openLoader());

    const data = { acceptance_note: acceptanceNote };

    return requestFromServer
      .acceptEMoneyRequestApi(id, data) // Pass the data here
      .then((response) => {
        if (response.status) {
          dispatch(ReplaceEmoneyActions.clearReplaceEmoneyDetails());
          if (onSuccess) onSuccess();
        }
      })
      .catch((error) => {
        dispatch(ReplaceEmoneyActions.catchError(error));
      })
      .finally(() => {
        dispatch(UIActions.closeLoader());
      });
  };

export const rejectReplaceEmoneyRequest =
  (id, rejectionNote, onSuccess) => (dispatch) => {
    dispatch(UIActions.openLoader());

    const data = { rejection_note: rejectionNote };

    return requestFromServer
      .rejectEMoneyRequestApi(id, data) // Pass the data here
      .then((response) => {
        if (response.status) {
          dispatch(ReplaceEmoneyActions.clearReplaceEmoneyDetails());
          if (onSuccess) onSuccess();
        }
      })
      .catch((error) => {
        dispatch(ReplaceEmoneyActions.catchError(error));
      })
      .finally(() => {
        dispatch(UIActions.closeLoader());
      });
  };
