import React, { useState } from "react";
import Modal from "react-modal";

const ReplaceAcceptModal = ({
  isOpen,
  onClose,
  handleVerifyPayment,
  eMoney,
  setEMoney,
  acceptanceNote,
  setAcceptanceNote,
  requestDetails,
  userDetails,
}) => {
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (eMoney <= 0) {
      setError("E-Money value must be greater than 0");
    } else {
      setError("");
      handleVerifyPayment("accept");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Accept Request Modal"
      className={"flex-col-center"}
    >
      <div className="custom-modal shadow-lg">
        <div className="modal-header">
          <h2>Accept Request</h2>
          <button onClick={onClose} className="btn btn-danger">
            Close
          </button>
        </div>
        <div className="modal-content">
          <div className="request-header">
            {requestDetails.request_number && (
              <p>
                Request No: <span>{requestDetails.request_number}</span>
              </p>
            )}
            <p>
              Request Date:{" "}
              <span>
                {new Date(requestDetails.request_date).toLocaleString()}
              </span>
            </p>
            <p>
              Request Status: <span>{requestDetails.request_status}</span>
            </p>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <label>Acceptance Notes</label>
                <textarea
                  value={acceptanceNote}
                  onChange={(e) => setAcceptanceNote(e.target.value)}
                  className="form-control"
                ></textarea>
              </div>

              <div className="form-group">
                <label>E-Money Value *</label>
                <input
                  type="number"
                  value={eMoney}
                  onChange={(e) => setEMoney(e.target.value)}
                  className="form-control"
                />
                {error && <p style={{ color: "red" }}>{error}</p>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="request-body  d-flex flex-column">
                <div className="request-section">
                  <h3>Earning Wallet Balance for</h3>
                  <p>{userDetails?.name}</p>
                  <p className="text-danger font-weight-bold">
                    {userDetails?.wallet} e.m
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button onClick={handleSubmit} className="btn btn-success">
                  Confirm
                </button>
                <button onClick={onClose} className="btn btn-secondary">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReplaceAcceptModal;
